var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Ara","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"icon":""},on:{"click":_vm.getItems}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-btn',{attrs:{"elevation":"0","to":{ name: 'admin-press-create' },"color":"secondary"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-newspaper-variant")]),_vm._v(" HABER EKLE ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Yükleniyor..."},scopedSlots:_vm._u([{key:"item.previewImg",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2",attrs:{"src":item.previewImg,"alt":"","width":"50","height":"50","contain":""}})]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pl-4"},[_c('v-badge',{attrs:{"dot":"","color":item.disabled ? 'error' : 'success'}})],1)]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dateCreated.toDate().toLocaleDateString("tr-TR")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary","to":{
                  name: 'admin-press-edit',
                  params: { id: item.id }
                },"exact":true}},[_vm._v("AÇ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }