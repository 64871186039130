











































































import { Vue, Component } from "vue-property-decorator";
import firebase from "@/plugins/firebase";

@Component({
  metaInfo: {
    title: "Basında KAHEV"
  }
})
export default class AdminNews extends Vue {
  search = "";
  loading = false;
  headers = [
    {
      text: "Önizleme Resmi",
      align: "start",
      filterable: true,
      value: "previewImg"
    },
    { text: "Başlık", value: "title" },
    { text: "Durum", value: "disabled" },
    { text: "Oluşturulma", value: "dateCreated" },
    { text: "", value: "actions", sortable: false, width: "50px" }
  ];
  items = [] as News[];
  selectedItem = {};

  async getItems() {
    this.loading = true;

    try {
      this.items = [];

      // Get all news
      const query = await firebase
        .firestore()
        .collection("/apps/kahev-org/press")
        .orderBy("dateCreated", "desc")
        .get();

      query.forEach(doc => {
        if (doc.exists) {
          let newItem = {} as News;
          newItem = doc.data() as News;
          newItem.id = doc.id;

          this.items.push(newItem);
        }
      });

      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.$notify({
        type: "error",
        text: `Haber listesi alınamadı.`
      });
      console.error(err);
    }
  }

  mounted() {
    this.getItems();
  }
}
